<template>
    <div class="form-group songSelector">
        <div class="l8 @mb-2">
            Which song is this for?
        </div>
        <MkSelect
            v-if="showStoriesDropdown"
            v-model="selectedStoryId"
            :options="storiesList"
            placeholder="Select a song"
            required
        />
        <div v-if="showCustomUrlInput" class="inputField" :class="{'@mt-4': storiesList.length}">
            <div v-if="!selectedStory">
                <input
                    v-model="otherUrl"
                    type="url"
                    class="form-control !@mt-0"
                    placeholder="Enter or paste song URL here"
                    required
                >
                <button class="mkButton short beige noMinWidth" :disabled="loadingStory" @click.prevent="getStory()">
                    add
                </button>
            </div>
            <div v-else class="@flex @justify-between @items-center @gap-4 p3 @text-sfc-dark-grey songText">
                <div class="@px-4">
                    <div v-text="selectedStory.text"/>
                    <div v-if="selectedStory.artist_name">
                        By {{selectedStory.artist_name}}
                    </div>
                </div>
                <div class="closeButton" @click="store.commit('store/selectStory', null)"/>
            </div>
        </div>
        <RequiredFormHelper v-if="showCustomUrlInput && !selectedStory" message="Please select song"/>
        <SlideUpDown class="text-danger @text-left" :model-value="urlError">
            <div class="@py-2" v-html="urlError"/>
        </SlideUpDown>
        <router-link
            v-if="!user && !selectedStory"
            :to="{name: 'Login', query: {...loginQuery}}"
            class="@text-sfc-medium-grey l7 @mt-2 @underline @underline-offset-2"
        >
            Sign in to load purchased songs
        </router-link>
    </div>
</template>

<script lang="ts" setup>
    import axios from "axios";
    import dayjs from "dayjs";

    import {ref, computed, watch, nextTick} from "vue";
    import {useRoute} from "vue-router";
    import {useStore} from "vuex";

    import MkSelect from "@songfinch/customer/components/shared/MkSelect.vue";
    import RequiredFormHelper from "@songfinch/shared/components/RequiredFormHelper.vue";
    import SlideUpDown from "@songfinch/shared/components/SlideUpDown.vue";

    import get_error from "@songfinch/shared/helpers/get_error";

    import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";
    import useStory from "@songfinch/customer/composables/useStory";

    const props = defineProps({
        product: {
            type: Object,
            default: null
        }
    });

    const emit = defineEmits(["story-changed"]);

    const route = useRoute();
    const store = useStore();
    const {story} = useStory();

    const storiesList = ref();
    const otherUrl = ref();
    const urlError = ref();
    const selectedStoryId = ref(store.state.store.selectedStory?.id);

    const loadingStory = ref(false);

    const authCallback = () => {
        if (route.name !== "ProductSingle") {
            store.commit("store/showProductDetailModal", props.product.name);
        }
    };

    const loginQuery = ref(
        (route.name === "ProductSingle") ?
            undefined :
            {
                afterLoginCallback: authCallback,
                skipRedirect: true
            });

    const user = computed(() => store.state.auth.user);
    const cart = computed(() => store.state.cart.cart);
    const selectedStory = computed(() => store.state.store.selectedStory);

    const showStoriesDropdown = computed(() => {
        return storiesList.value?.length && !(selectedStory.value?.id && selectedStoryId.value === "other");
    });

    const showCustomUrlInput = computed(() => {
        if (selectedStoryId.value === "in_cart") {
            return false;
        } else if (selectedStoryId.value === "other" || !user.value || (storiesList.value && !storiesList.value.length)) {
            return true;
        }
        return false;
    });

    watch(user, () => {
        selectedStoryId.value = null;
        checkForStories();
    });

    watch(selectedStoryId, (val) => {
        if (["other", "in_cart"].includes(val)) {
            store.commit("store/selectStory", null);
        } else {
            const selectedStory = storiesList.value.find(s => s.id === val);
            store.commit("store/selectStory", selectedStory);
        }
    });

    watch(() => selectedStory.value?.id, () => {
        emit("story-changed");
    });

    const formatStory = (s) => {
        s.id = String(s.id); //MkSelect require string
        if (!["shipped", "edit_sent", "edit_rejected", "edit_completed", "edit_in_progress"].includes(s.status)) {
            s.text = `Song #${s.order_id} – ${dayjs(s.created_at).format("MM/DD/YYYY")} (in progress)`;
        }
        s.products = s.products?.split(",").map(Number);
        return s;
    };

    const selectStory = () => {
        const selectedStory = {
            id: story.value?.story_id,
            created_at: story.value?.created_at,
            slug: story.value?.slug,
            status: story.value?.status,
            owner_id: story.value?.owner_id,
            order_id: story.value?.order_id,
            products: story.value?.products,
            is_instant: !!story.value?.instant_product,
            text: story.value?.song?.title || story.value?.instant_product?.title,
            artist_name: story.value?.song?.artist?.fullName || story.value?.instant_product?.artist_display_name
        };
        store.commit("store/selectStory", formatStory(selectedStory));
    };

    const checkForStories = async () => {
        storiesList.value = [];
        if (["Story"].includes(route.matched[0]?.name as string)) {
            selectStory();
        }

        if (cart.value.hasOriginalSong || cart.value.hasInstantProduct) {
            storiesList.value.push({
                id: "in_cart",
                is_instant: !!cart.value.hasInstantProduct,
                text: cart.value.hasInstantProduct ? "Jukebox song (in cart)" : "Custom song (in cart)"
            });
            if (route.name !== "Story") {
                selectedStoryId.value = "in_cart";
            }
        }

        if (user.value) {
            try {
                const res = await axios.get("store/get_stories");
                const list = res.data.stories.map(formatStory);
                storiesList.value = storiesList.value.concat(list);
                if (selectedStory.value?.id) {
                    selectedStoryId.value = storiesList.value.find(s => s.id === selectedStory.value?.id)?.id || "other";
                    await nextTick();
                    store.commit("store/selectStory", selectedStory.value);
                }
            } catch (e) {
                $toastMsg(get_error(e));
            }
        }
        if (storiesList.value.length && props.product.name !== "extra-verse") {
            storiesList.value.push({id: "other", text: "Find a song / enter URL"});
        }
    };

    const getStory = async (story_slug?: string) => {
        urlError.value = undefined;
        if (loadingStory.value) return;
        loadingStory.value = true;
        try {
            if (!story_slug) {
                const url = new URL(otherUrl.value);
                const pathArr = url?.pathname.split("/");
                if (pathArr[1] !== "stories" || !pathArr[2]) {
                    throw new Error(
                        `Missing requested story slug. Error loading url: ${url} because it does not start with /stories`
                    );
                }
                story_slug = pathArr[2];
            }

            const res = await axios.get("store/get_stories", {params: {story_slug}});
            if (!res.data.stories?.[0]) {
                throw new Error(
                    `Invalid or missing provided argument: story_slug: ${story_slug}`
                );
            }
            store.commit("store/selectStory", formatStory(res.data.stories[0]));
        } catch (e) {
            urlError.value = "Invalid URL. Please enter URL of song. Example: https://www.songfinch.com/stories/392550e1-c8e3-4fbc-a80f-ef2daf2173a3";
        }
        loadingStory.value = false;
    };
    checkForStories();
</script>

<style scoped>
    .songSelector {
        .inputField {
            position: relative;

            input {
                padding-right: 80px;
            }

            .mkButton {
                position: absolute;
                top: 50%;
                right: 9px;
                transform: translateY(-50%);
            }
        }

        .songText {
            .closeButton {
                position: relative;
                top: 0;
            }
        }
    }
</style>
