<template>
    <div
        v-if="$productTags[tag]"
        class="productTag l9"
        :style="$productTags[tag].custom_styles || $productTags[tag].styles"
        v-text="tag"
    />
</template>

<script>
    export default {
        name: "ProductTag",
        props: {
            tag: {
                type: String,
                default: ""
            }
        }
    };
</script>

<style scoped>
    .productTag {
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 10px;
        padding: 5px 8px;
        border-radius: 3px;
    }
</style>
