<template>
    <label
        v-if="options"
        ref="dropdown"
        v-click-outside="close"
        class="form-group mkSelectBox @cursor-pointer @select-none"
    >
        <!--    Helper for autofill    -->
        <select
            :value="selectedValue"
            :multiple="multiple"
            style="display: none;"
            @change.stop="change"
        >
            <option v-if="placeholder" value="" v-text="placeholder"/>
            <option
                v-for="v in formatedOptions"
                :key="v.id"
                :value="v.id"
                v-text="v.optionText || v.text"
            />
        </select>
        <!--   End Helper for autofill    -->
        <div
            ref="selectArrow"
            class="selectArrow"
            tabindex="0"
            @keydown.space.enter.prevent="toggleShow"
        >
            <input
                type="text"
                class="form-control @cursor-pointer"
                :class="inputClass"
                tabindex="-1"
                :placeholder="placeholder"
                :value="selectedText"
                :required="required"
                :readonly="readonly"
                autocomplete="none"
                data-cy="outputBox"
                @focus="$event.target.blur()"
                @click="toggleShow"
            >
        </div>
        <div v-if="show" class="mkSelectDropdown">
            <div class="box">
                <div class="optionItem optionSelected" @click.stop="close">
                    <div class="optionBox">
                        <span v-if="selectedText" class="l3 optionContent" v-html="selectedText"/>
                        <span v-else class="p3 @text-sfc-medium-grey">{{placeholder}}</span>
                        &nbsp;
                    </div>
                    <div class="closeButton"/>
                </div>
                <div class="scrollableOptions">
                    <label
                        v-for="(v, i) in formatedOptions"
                        :key="v.id"
                        ref="optionItems"
                        class="optionItem"
                        :class="{disabled: v.disabled}"
                        tabindex="0"
                        @click.stop
                        @keydown.space.enter.prevent="keySelectOption(i)"
                    >
                        <input
                            ref="options"
                            type="checkbox"
                            :value="v.id"
                            :disabled="!!v.disabled"
                            :checked="selectedValue.includes(v.id)"
                            tabindex="-1"
                            @change.stop="change"
                        >
                        <div class="optionBox">
                            <div class="optionContent" v-html="v.optionText || v.text"/>
                            <img :src="checkedIcon" class="checked" alt="check icon">
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </label>
</template>

<script>
    import click_oustide_directive from "@songfinch/shared/mixins/click_oustide_directive";
    import checkedIcon from "@songfinch/customer/assets/images/checked.svg";
    import {map} from "lodash-es";

    export default {
        name: "MkSelect",
        mixins: [click_oustide_directive],
        props: {
            modelValue: {type: [String, Array], default: ""}, //Accepting String or Array of values
            options: {type: [Array, Object], default: null}, //array of string ['opt1', 'opt2'] Or array of object [{id: 'val', text: 'Text', optionText: '<u>Text<u/>'}], optionText - optional
            available: {type: Array, default: null}, //array of vals that available
            placeholder: {type: String, default: ""},
            inputClass: {type: String, default: ""},
            required: Boolean,
            readonly: Boolean,
            multiple: Boolean,
            max: {
                type: [Number, String],
                default: 1
            }
        },
        emits: ["update:modelValue"],
        data() {
            return {
                show: false,
                checkedIcon,
            };
        },
        computed: {
            formatedOptions() {
                return map(this.options, q => {
                    const result = (typeof q === "string") ? {id: q, text: q} : {...q};
                    if (this.available?.length) {
                        result.disabled = !this.available.includes(result.id);
                    }
                    if (this.multiple && this.max && this.modelValue.length >= this.max) {
                        result.disabled = !this.modelValue.includes(result.id);
                    }
                    return result;
                });
            },
            selectedItems() {
                const vals = Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue];
                return this.formatedOptions.filter(q => vals.includes(q.id));
            },
            selectedValue() {
                return this.selectedItems.map(q => q.id);
            },
            selectedText() {
                return this.selectedItems.map(q => q.text ).join(", ");
            },
        },
        watch: {
            show(val) {
                if (val) {
                    document.addEventListener("keydown", this.keydownHandler);
                } else {
                    document.removeEventListener("keydown", this.keydownHandler);
                }
            }
        },
        beforeUnmount() {
            document.removeEventListener("keydown", this.keydownHandler);
        },
        methods: {
            keySelectOption(i) {
                this.$refs.options?.[i].click();
                this.$refs.selectArrow?.focus();
            },
            toggleShow() {
                if (this.readonly) return;
                this.show = !this.show;
            },
            change(e) {
                if (this.multiple) {
                    const value = this.modelValue;
                    const index = value.indexOf(e.target.value);
                    if (index !== -1) {
                        value.splice(index, 1);
                    } else {
                        this.$emit("update:modelValue", [...value, e.target.value]);
                    }
                } else {
                    this.$emit("update:modelValue", e.target.value);
                    this.show = false;
                    // this.$refs.dropdown.scrollIntoView({
                    //     top: 80,
                    //     block: 'center'
                    // });
                }
            },
            close() {
                if (this.show) {
                    this.show = false;
                }
            },
            keydownHandler(e) {
                //Escape
                if (e.keyCode === 27 ) return this.close();
                // Focus option on key press
                if (/^[a-z]$/.test(e.key)) {
                    if (!this.$refs.optionItems) return;
                    const matched = this.$refs.optionItems.filter(o => o.innerText.toLowerCase().charAt(0) === e.key);
                    if (!matched.length) return;
                    let nextActive = matched.findIndex(el => el === document.activeElement) + 1;
                    if (nextActive === matched.length) nextActive = 0;
                    matched[nextActive].focus();
                }
            }
        }
    };
</script>

<style scoped>
    @import "@songfinch/customer/stylesheets/includes/mixins/index.pcss";
    //##########################################################################
    .mkSelectBox {
        position: relative;
        text-align: left;
        display: block;

        &.capitalizeOptions {
            .optionContent:first-letter,
            .selectedItem:first-letter {
                text-transform: capitalize;
            }

            input,
            .optionSelected .optionContent {
                text-transform: capitalize;
            }
        }

        input {
            text-align: left;

            &[readonly] {
                background: var(--sfc-white);
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .mkSelectDropdown {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 102;
            padding-bottom: 20px;
            @include font(National, 18px, 24px, 300);

            .box {
                background-color: var(--sfc-white);
                border-radius: 3px;
                box-shadow: 0 0 0 3px var(--sfc-blue);
                overflow-x: hidden;

                .scrollableOptions {
                    overflow-y: auto;
                    max-height: 250px;
                }

                .optionItem {
                    cursor: pointer;
                    text-transform: none;
                    display: block;
                    width: 100%;

                    input {
                        display: none;
                    }

                    .optionBox {
                        display: block;
                        color: var(--sfc-dark-grey);
                        background: var(--sfc-white);
                        padding: 12px 50px 12px 18px;
                        border-bottom: 1px solid var(--sfc-dark-beige);
                        position: relative;

                        .optionContent {
                            div {
                                color: var(--sfc-medium-grey);
                                @include font(National, 16px, 22px, 300);
                            }
                        }

                        .checked {
                            opacity: 0;
                            position: absolute;
                            top: 24px;
                            right: 17px;
                            transform: translateY(-50%);
                            transition: all 0.3s;
                        }
                    }

                    input:checked + .optionBox {
                        background: rgba(0, 101, 240, 0.1);

                        .checked {
                            opacity: 1;
                        }
                    }

                    @include hover-device {
                        &:hover .optionBox {
                            background: rgba(0, 101, 240, 0.1);
                        }
                    }

                    &:focus .optionBox {
                        background: rgba(0, 101, 240, 0.1);
                    }

                    &:last-child {
                        span {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }

    .selectArrow {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            right: 3px;
            width: 40px;
            height: calc(100% - 5px);
            transform: translateY(-50%);
            pointer-events: none;
            border-radius: 10px;
        }

        &:after {
            z-index: 2;
            content: "";
            padding: 4px;
            position: absolute;
            top: calc(50% - 7px);
            right: 20px;
            pointer-events: none;
            border: solid var(--sfc-medium-grey);
            border-width: 0 2px 2px 0;
            display: inline-block;
            transform: rotate(45deg);
        }
    }
</style>
