<template>
    <div class="spotifyIcon">
        <img :src="onList ? icon_ls : icon_dt" alt="Listen On Spotify">
    </div>
</template>

<script>
    import icon_dt from "@songfinch/customer/assets/images/spotify_details.png";
    import icon_ls from "@songfinch/customer/assets/images/spotify_list.png";

    export default {
        name: "SpotifyIcon",
        props: {onList: {type: Boolean}},
        data() {
            return {
                icon_dt,
                icon_ls,
            };
        }
    };
</script>

<style scoped>
    .spotifyIcon {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;

        img {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            max-width: 220px;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 4e-05) 18.06%, rgba(0, 0, 0, 0.102113) 50.35%, rgba(0, 0, 0, 0.4) 100%);
            mix-blend-mode: multiply;
        }
    }
</style>
