<template>
    <div>
        <div v-if="slides.length" class="productImageSlider">
            <Slider
                ref="main"
                :arrow-next-style="{right: '-5px', left: 'auto'}"
                :arrow-prev-style="{left: '-5px'}"
                :options="mainSliderOptions"
                class="desktop:!@mb-4"
                :class="{'!@mb-8': !showThumb}"
            >
                <SplideSlide
                    v-for="(slide, i) in slides"
                    :key="i"
                    class="imageCover @pb-full @block @relative"
                    :style="{backgroundImage: $cld.bgUrl(slide.thumb ? slide.thumb : slide, slide.cldImage)}"
                >
                    <ProductTag :tag="product.extra_info?.tag"/>
                    <SpotifyIcon v-if="product.product_type === 'streaming'" :on-list="false"/>
                    <img
                        v-if="slide.scope === 'video'"
                        v-fancybox-directive="'productImage'"
                        class="@absolute @top-1/2 @left-1/2 @-translate-x-1/2 @-translate-y-1/2 @cursor-pointer fancybox"
                        :src="playButton"
                        :data-src="$cld.url(slide, slide.cldLinkSettings)"
                        :data-thumb="$cld.url(slide.thumb ? slide.thumb : slide, slide.cldImage)"
                        alt="play"
                    >
                    <div
                        v-else
                        v-fancybox-directive="'productImage'"
                        :style="{backgroundImage: `url(${fullscreenIcon})`}"
                        class="fullscreen @cursor-pointer fancybox"
                        :data-src="$cld.url(slide, slide.cldLinkSettings)"
                        :data-thumb="$cld.url(slide.thumb ? slide.thumb : slide, slide.cldImage)"
                    />
                </SplideSlide>
            </Slider>
            <div v-if="showThumb" class="@overflow-hidden">
                <Slider
                    ref="thumb"
                    :options="thumbSliderOptions"
                    :arrows="false"
                    class="@hidden desktop:@block @-mx-2.5 thumbSlider"
                    :class="{centerMode: thumbSliderOptions.centerMode}"
                >
                    <SplideSlide v-for="(slide, i) in slides" :key="i" class="@px-2.5">
                        <div
                            class="imageCover @pb-full @cursor-pointer"
                            :style="{backgroundImage: $cld.bgUrl(slide.thumb ? slide.thumb : slide, slide.cldImage)}"
                        />
                    </SplideSlide>
                </Slider>
            </div>
        </div>
    </div>
</template>

<script>
    import ProductTag from "./ProductTag";
    import SpotifyIcon from "./SpotifyIcon";
    import fullscreenIcon from "@songfinch/customer/assets/images/fullscreen.png";
    import playButton from "@songfinch/customer/assets/images/play_video.png";
    import fancyboxDirective from "@songfinch/customer/directives/fancybox/fancybox_directive";

    export default {
        name: "ProductImageSlider",
        components: {ProductTag, SpotifyIcon},
        directives: {fancyboxDirective},
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                fullscreenIcon,
                playButton,
                mainSliderOptions: {
                    type: "loop",
                    breakpoints: {
                        10000: {
                            pagination: false
                        },
                        1199: {
                            pagination: true
                        }
                    }
                },
                thumbSliderOptions: {
                    perPage: 6,
                    pagination: false,
                    isNavigation: true
                },
                imageFormat: {
                    transformation: [{width: 720, crop: "scale"}]
                },
                showThumb: true,
                slides: []
            };
        },
        created() {
            if (this.product.photo) {
                this.slides.push({...this.product.photo, cldImage: this.imageFormat});
            }
            if (this.product.video) {
                this.slides.push({
                    ...this.product.video,
                    thumb: this.product.video_th,
                    scope: "video",
                    cldImage: {...this.imageFormat, resource_type: "video"},
                    cldLinkSettings: {resource_type: "video", format: "mp4"}
                });
            }
            if (this.product.more_photos?.length) {
                this.slides = this.slides.concat(
                    this.product.more_photos.map( v => ({...v, cldImage: this.imageFormat}))
                );
            }
            if (!this.slides.length) {
                this.slides.push(this.$defaultImage);
            }
            if (this.slides.length === 6) {
                this.slides = this.slides.concat(this.slides);
            }
            if (this.slides.length < 2) this.showThumb = false;
            if (this.slides.length < 6) {
                this.thumbSliderOptions.centerMode = true;
                this.thumbSliderOptions.perPage = 6;
                this.thumbSliderOptions.padding = 0;
                this.thumbSliderOptions.focus = "";
            } else {
                this.thumbSliderOptions.centerMode = false;
                this.thumbSliderOptions.perPage = 5;
                this.thumbSliderOptions.padding = {right: "7%"};
                this.thumbSliderOptions.focus = "center";
            }
        },
        mounted() {
            setTimeout(() => {
                if (this.slides.length > 1) this.$refs.main?.$refs.splide.sync(this.$refs.thumb?.$refs.splide.splide);
            }, 500);
        }
    };
</script>

<style scoped>
    .productImageSlider {
        .fullscreen {
            position: absolute;
            bottom: 0;
            right: 0;
            transition: all 0.3s;
            width: 40px;
            height: 40px;
            background-position: center;
            background-repeat: no-repeat;

            &:hover {
                scale: 1.1
            }
        }

        :deep(.thumbSlider) {
            padding-bottom: 3px;

            .splide__slide{
                border: none !important;
            }
            .is-active.is-visible {
                border: none !important;
                .imageCover {
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        top: calc(100% - 3px);
                        left: 0;
                        height: 3px;
                        width: 100%;
                        background: var(--sfc-red);
                    }
                }
            }
            &.centerMode{
                margin: 0 !important;
                .splide__list{
                    justify-content: center !important;
                }
            }
        }
    }
</style>
