<script lang="ts" setup> 
    import {computed} from "vue";
    import {useStore} from "vuex";
    import {useRoute} from "vue-router";

    import DSiteNavigation from "@songfinch/design-system/src/navigation/DSiteNavigation.vue";

    // import {formatPriceToUSD} from "@songfinch/utils/src/format/string";
    import {normalizeSiteNavigation} from "@songfinch/utils/src/normalizers/cms";

    import type {User, Navigation} from "@songfinch/types";

    defineProps<{
        hasTopBanner: boolean;
    }>();

    const route = useRoute();
    const store = useStore();

    const navigation = computed<Navigation>(() => {
        if (!store.state.cms?.navigation?.new_menu) return [];
        return normalizeSiteNavigation(store.state.cms?.navigation?.new_menu);
    });

    const user = computed<User>(() => store?.state?.auth?.user);
    const cartLength = computed(() => store.state.cart.cart.length());

    const removeClearfix = computed(() => !!route.matched?.find(r => r.meta.removeClearfix));
    const baseTheme = computed(() => (route?.meta?.baseTheme as "light" | "dark") || "light");
</script>

<template>
    <DSiteNavigation 
        :user="user" 
        :cartLength="cartLength" 
        :navigation="navigation" 
        :hasTopBanner="hasTopBanner"
        :removeClearfix="removeClearfix"
        :baseTheme="baseTheme"
        @logout="store.dispatch('auth/logout')"
    />
</template>